const translateUz = {
    about:"Asosiy",
    aboutUs:"Biz haqimizda",
    review:'Otzivlar',
    contact:'Biz bilan aloqa',
    call:'Kontaktlar',
    email:'Elekton pochta:',
    address:'Manzil',
    location:"Toshkent shaxar, Mirzo Ulug'bek tumani, Shahriobod SHFY, Oliy Himmat ko`chasi",
    app:'Ijtimoiy tarmoqlar',
    companyName:'Kompaniyaning nomi yoki yakka tartibdagi tadbirkor',
    products:'Bizning ishlab chiqarish:',
    advice:"Bepul maslahat uchun ro'yxatdan o'ting",
    writeOrder:"Quyidagi shaklni to'ldiring va menejerlarimiz siz bilan bog'lanadi",
    sendPetition:"Arizangizni yuboring",
    office:"Bosh ofis",
    hpone:"Telefon nomer:",
    OfficeOpeningHours:"Ofisning ish vaqti:",
    daily:"Har kuni soat 8.00 dan 17.00 gacha",
    review:"Sharhlar",
    leaveReview:'Sharh qoldiring',
    sendReview:"Sharhni yuborish",
    headerTitle:'OOO "PRO-ZED',
    certificateNumber:"Sertifikat raqami:",
    headerDescription:"Mahsulotlarimiz bilan baxtli ota-onaga aylaning. Siz eng yaxshisiga loyiqsiz!!",
    headerBtn:"Bizning ishlab chiqarish",
    buy:'Sotib olish',
    informProduct:"Mahsulot haqida batafsil",
    whatIsBad:"Xun qo'shimchalari nima?",
    whatIsBadDescription:"Biologik faol oziq-ovqat qo'shimchalari (BAA) - bu oziq-ovqat bilan bevosita qabul qilish yoki oziq-ovqatga kiritish uchun mo'ljallangan biologik faol moddalar va ularning tarkibi. Diyetik qo'shimchalar - minerallar, vitaminlar, xun tolasi, o'simlik ekstraktlari, to'yinmagan yog'li kislotalar, aminokislotalar va boshqalarni o'z ichiga olgan tabiiy komplekslar. Ular ozuqaviy etishmovchiliklarni bartaraf qiladi, ozuqa moddalarining so'rilishini yaxshilaydi, shuningdek, tananing ichki kuchini oshiradi, buning xavfini kamaytiradi. ko'plab kasalliklar. Oziq-ovqat qo'shimchalari majmuasi asosan o'simliklarning barglari, gullari, ildizlari va mevalaridan olinadi, ularni tayyorlashda bakteriyalar, mikroblar, ayrim hayvonlarning organlari va mineral xom ashyolardan ham foydalaniladi.",
    aboutProzed:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    btnocompany:"Kompaniya haqida",
    supplementDepression:"Depressiya, aqliy mehnatdan keyin charchoq va g'ayritabiiy sport mashg'ulotlaridan keyin tanani tiklash uchun parhez qo'shimchasi sifatida ishlatiladi.",

    aboutProzed:"Sog'lik salohiyatingizni zamonaviy BFQ takviyeleri bilan oching!",
    originCompany:"Kompaniyaning kelib chiqishi Sog'lomlikka bo'lgan ishtiyoq ilmiy tajriba bilan uyg'unlashgan PRO-ZED MChJga xush kelibsiz! Biz kompaniyamizni yagona vazifa bilan asos solganmiz: odamlarga eng yaxshi sog'lik va hayotga erishish yo'lida yordam berish.",
    drivenDeep:"Oziqlanishning farovonligimizdagi muhim rolini chuqur anglagan holda, biz tabiat kuchi va ilmiy innovatsiyalardan foydalanadigan yuqori sifatli ozuqaviy qo'shimchalar qatorini yaratishga kirishdik. Sifat va samaradorlikka bo'lgan sodiqligimiz brendimizning asosiga aylandi, bu biz taklif qilayotgan har bir mahsulot juda ehtiyotkorlik bilan ishlab chiqilganligini va keng qamrovli tadqiqotlar bilan qo'llab-quvvatlanishini ta'minlaydi.",
    PROZEDLLC:"PRO-ZED MChJda biz chinakam salomatlik yaxlit yondashuv orqali erishiladi deb hisoblaymiz. Biz sizga energiyani oshirish va immunitetni qo'llab-quvvatlashdan kognitiv funktsiyani yaxshilash va muvozanatli kayfiyatni saqlashgacha bo'lgan keng ko'lamli sog'liqni saqlash maqsadlariga javob beradigan keng tanlov qo'shimchalarini taqdim etishga intilamiz.",

    freeDeliver:"O'zbekiston bo'ylab yetkazib berish bepul",
    knowMoreBtn:"Qo'shimcha ma'lumot olish uchun",
    биологическиAктивных:"Ilg'or xun qo'shimchalari bilan sog'lig'ingiz potentsialini oching!",
    aboutCompany:"Kompaniyaning kelib chiqishi",
    aboutCompanyDescription:"Sog'likka bo'lgan ishtiyoq ilmiy tajribaga mos keladigan [Kompaniya nomi] ga xush kelibsiz! Biz kompaniyamizni yagona vazifa bilan asos solganmiz: odamlarga eng yaxshi sog'lik va hayotga erishish yo'lida yordam berish. Oziqlanishning farovonligimizdagi muhim rolini chuqur anglagan holda, biz tabiat kuchi va ilmiy innovatsiyalardan foydalanadigan yuqori sifatli ozuqaviy qo'shimchalar qatorini yaratishga kirishdik. Sifat va samaradorlikka bo'lgan sodiqligimiz brendimizning asosiga aylandi, bu biz taklif qilayotgan har bir mahsulot juda ehtiyotkorlik bilan ishlab chiqilganligini va keng qamrovli tadqiqotlar bilan qo'llab-quvvatlanishini ta'minlaydi. [Kompaniya nomi] biz chinakam salomatlikka yaxlit yondashuv orqali erishiladi deb ishonamiz. Biz sizga energiyani oshirish va immunitetni qo'llab-quvvatlashdan kognitiv funktsiyani yaxshilash va muvozanatli kayfiyatni saqlashgacha bo'lgan keng ko'lamli sog'liqni saqlash maqsadlariga javob beradigan keng tanlov qo'shimchalarini taqdim etishga intilamiz.",
    ourAchievement:"Bizning yutuqlarimiz",
    companyAge:"2 yosh",
    sinceOpened:"kompaniya ochilganidan beri",
    achievedResalts:"natijalarga erishgan mijozlar",
    boughtProducts:"Mahsulotlarimizni sotib oldik va iste'mol qildik",
    certificates:"Sertifikatlar",
    aboutFounder:"Ta'sischi haqida",
    AksenovaValentina:"Aksenova Valentina Ivanovna - Moskva hukumati Moskva tibbiyot palatasining bosh epidemiologi, tabiiy tibbiyot magistri, makrobiologiya fanlari doktori, professor, psixologiya fanlari doktori. 'Optisalt' kompaniyasi asoschisi va ilmiy rahbari, parazitologiya, gelmintologiya, mikroelementologiya, murakkab bioaktiv dorivor komponentlarni (nutratsevtiklar) ishlab chiqish bo'yicha mutaxassis, 20 yildan ortiq amaliy tajribaga ega.",
    EuropeanAcademy:"Evropa tabiiy fanlar akademiyasining a'zosi, Rossiya tabiiy tibbiyot ilmiy jamiyati a'zosi, Milliy salomatlik ligasi a'zosi, naturoterapiya notijorat tashkiloti a'zosi.Xalqaro Bolqon qoʻshimcha va muqobil tibbiyot akademiyasi maslahat kengashi aʼzosi.",
    readMore:"Ko'proq o'qish",
    ourPartners:"Bizning hamkorlarimiz",
    sleepQuality:"Spermogenezning sifat va miqdoriy ko'rsatkichlarini normallashtirish",
    increaseMaleStrength:"Erkak kuchini oshirish uchun",
    excitabilityOfSperm:"Spermatozoidlarning sonini va qo'zg'aluvchanligini oshirish",
    spermCount:"Oddiy sperma sonini oshirish uchun morfologik",
    supplementDepression:"Depressiya, aqliy mehnatdan keyin charchoq va g'ayritabiiy sport mashg'ulotlaridan keyin tanani tiklash uchun parhez qo'shimchasi sifatida ishlatiladi",
    normalizesMenopause:"Menopauza davrini normallashtiradi",
    normalizesEndometrial:"Endometrium qalinligini optimal qiymatlarga normallashtiradi",
    bodyVitamins:"Tanani vitaminlar va minerallar bilan to'yintiradi",
    improvesCondition:"Ayollarning reproduktiv tizimining holatini yaxshilaydi",
    improvesFemaleBody:"Ayol tanasining farovonligi va umumiy holatini yaxshilaydi",
    doublesPregnancy:"Homiladorlik darajasini ikki baravar oshiradi",


    // ESTROZED

    
    estrozed:"E S T R O Z E D",
    estrozedabout:"(Homiladorlik + vitamin kompleksiga tayyorgarlik)",
    estrozedinstrucy:"Foydalanish bo'yicha ko'rsatmalar",
    estrozedtradename:"Savdo nomi: Estrosed – xun takviyesi (xun takviyesi).",
    estrozedcapsules:"Chiqarish shakli: har bir paket uchun 90 kapsula.",
    estrozedcompound:"Tarkibi: L-arginin, L-karnitin, E vitamini, sink oksidi, foliy kislotasi, magniy karbonat, vitamin B6, vitamin B8, vitamin C, koenzim Q10, selen, yod, Viteks ekstrakti (iridoidlarni o'z ichiga oladi), Soya isaflavon.",
    estrozedаргинин:"L-arginin 100 mg",
    estrozedкарнитин:"L-karnitin 200 mg",
    estrozedvitaminE:"E vitamini 18 mg",
    estrozedoxide:"Sink oksidi 10 mg",
    festrozedolicAcid:'Foliy kislotasi 40 mkg',
    estrozedmagnesiumCarbonate:"Magniy karbonat 100 mg",
    estrozedvitaminB6:"B6 vitamini 1,8 mg",
    estrozedvitaminB8:"B8 vitamini 65 mg",
    estrozedvitaminC:"S vitamini 30 mg",
    estrozedCoenzyme:"Koenzim Q10 15 mg",
    estrozedselenium:"Selen 40 mkg",
    estrozediodine:"Yod 150 mkg",
    estrozedchasteberry:"Chasteberry ekstrakti (iridiodlar mavjud) 40 mg",
    estrozedisaflavone:"Soya isaflavon 100 mg",
    estrozedexcipients:"Yordamchi moddalar: kraxmal, laktoza, kaltsiy stearati.",
    estrozedestrozedProperties:"ESTROZED xususiyatlari va komponentning ta'sir qilish mexanizmi:",
    estrozedl_аргинин:"L-arginin",
    estrozedaminoAcid:"Azot oksidi hosil bo'lgan aminokislota, bu vazodilatatsiyaga yordam beradi va natijada qon aylanishini va to'qimalarning kislorod bilan to'yinganligini yaxshilaydi, shu jumladan. tos a'zolarida. Bu reproduktiv tizim organlarining holatini yaxshilaydi va kontseptsiya ehtimolini oshiradi.",
    estrozedl_carnitine:"L-karnitin",
    estrozedforWeightLoss:"Kilogramm yo'qotish uchun, ortiqcha yuk paytida chidamlilikni oshirish, shu jumladan sport, menopauza va parhezlar paytida. Bu ortiqcha yog'larni yoqish, mushaklarni qurish, tanani tonish va miya va yurak faoliyatini yaxshilashga yordam beradi.",
    estrozedvitaminE2:"E vitamini",
    estrozedimprovesOxygen:"Oqsillar, yog'lar va uglevodlar almashinuvi uchun zarur bo'lgan to'qimalarning kislorod bilan to'yinganligini yaxshilaydi. E vitaminining antioksidant funktsiyasi muhim ahamiyatga ega, buning natijasida u hujayralarni erkin radikallarning zararli ta'siridan himoya qiladi. Gipovitaminoz E bilan ayol doimiy ravishda mushaklar kuchsizligini, og'riqni his qiladi va majburiy abort qilish xavfi ortadi. Onaning tanasida E vitamini etishmasligi erta tug'ilgan chaqaloqlarga ta'sir qilishi mumkin - ularda ko'rish buzilishi va gemolitik anemiya bo'lishi mumkin.",
    estrozedzinc:"Sink",
    estrozedpregnancy:"Homiladorlik paytida eng muhim elementlardan biri. Hujayra yadrosining shakllanishi va rivojlanishi, shuningdek hujayra o'sishi va bo'linishi uchun zarur bo'lgan genetik apparatning ishlashi uchun zarur. Sink nuklein kislotalar, oqsillar, yog'lar va uglevodlar sintezini tartibga solishda ishtirok etadi. Sinkni qabul qilish platsentaning erta ajralishi, murakkab tug'ilish, bolaning tug'ma nuqsonlari va boshqa muammolarning oldini olishga yordam beradi.",
    estrozedfolicAcid:"Foliy kislotasi",
    estrozednecessaryGrowth:"Bu hujayralar bo'linishi va o'sishi uchun zarurdir, shuning uchun tananing jadal rivojlanish davrida (intrauterin rivojlanishning dastlabki bosqichida) ko'proq miqdorda talab qilinadi. Folik kislota bachadonda platsenta va yangi qon tomirlarining shakllanishida ham muhim rol o'ynaydi. Kontseptsiyaga tayyorgarlik ko'rish va homiladorlik davrida foliy kislotasini qabul qilish homiladorlikning muddatidan oldin tugashi, qon ketishi va yangi tug'ilgan chaqaloqlarda asab naychalari nuqsonlari rivojlanishi xavfini kamaytiradi.",
    estrozedmagnesium:"Magniy",
    estrozedbiochemicalProcesses:"Organizmdagi 300 dan ortiq biokimyoviy jarayonlarni, jumladan, oqsil sintezini va aminokislotalarning faollashuvini tartibga soladi, bu ayniqsa homiladorlik davrida kuchli. Magniy, shuningdek, hujayralar orasidagi elektr signallarining uzatilishini ham ta'minlaydi. Magniy etishmovchiligi ayollarda tez-tez uchraydi va homiladorlik davrida ayniqsa xavflidir, chunki bu homila o'sishining pasayishiga, tushish, eklampsi, kramplar va yuqori qon bosimi bilan bog'liq homiladorlik buzilishiga olib kelishi mumkin.",
    estrozedvitaminB6:"Vitamin B6",
    estrozedaminoAcids:"Proteinlar hosil bo'ladigan aminokislotalarning sintezi uchun zarur - yangi organizm hujayralari uchun asosiy 'qurilish materiali'. Shuningdek, u bolaning miyasi va asab tizimining normal rivojlanishini ta'minlaydi. Bundan tashqari, B6 vitamini asabiylashish va tajovuzkorlikni kamaytiradi, homiladorlikning birinchi yarmida toksikozning namoyon bo'lishini kamaytiradi.",
    estrozedвитаминВ8:"Vitamin B8",
    estrozedforWomen:"Ayollar uchun bu vitaminga o'xshash eng muhim moddalardan biri bo'lib, organizm o'z ehtiyojlarining to'rtdan uch qismini o'zi ishlab chiqaradi va qolgan qismini ovqatdan oladi. Tadqiqotlar shuni ko'rsatadiki, inositolni oziq-ovqat bilan qo'shimcha iste'mol qilish adolatli jinsiy aloqada gormonal muvozanatni normallashtirishga yordam beradi.",
    estrozedvitaminC:"S vitamini",
    estrozedsynthesisOfCollagen:"Kollagen sintezini (qon tomirlari va suyak to'qimalari devorlarining asosiy bog'lovchi elementi) qo'llab-quvvatlaydi, xolesterin almashinuvida, uglevod almashinuvi jarayonlarida va ko'plab muhim biokimyoviy reaktsiyalarda ishtirok etadi. S vitamini ham kapillyarlarni mustahkamlaydi va yallig'lanishga qarshi xususiyatlarga ega. S vitamini etishmovchiligi membranalarning erta yorilishiga olib kelishi mumkin.",
    estrozedcoenzyme:"Koenzim Q10",
    estrozedhealthWholeBody:"Yoshlik va butun tananing salomatligi bilan bevosita bog'liq bo'lgan antioksidantlar guruhiga kiradi. Antioksidantlar hujayralar devorlarini erkin radikallar tomonidan yo'q qilinishidan himoya qilib, ularning hayot aylanishini uzaytirishga yordam beradi. Himoya qilishdan tashqari, koenzim kuchli energiya funktsiyasini bajaradi. U hujayralardan energiya chiqarishning murakkab biokimyoviy jarayonlarida ishtirok etadi, farovonlik va ishlashga ta'sir qiladi. Boshqacha qilib aytganda, Coenzyme Q10 tanamiz uchun tabiiy 'batareya' dir. Uning etishmasligi terining qarishi, miya faoliyatining pasayishi va immunitetning zaiflashishiga olib keladi.",
    estrozedselenium:"Selen",
    estrozedincreasingFertility:"Ko'p sonli fermentlarga kiritilgan, shu jumladan. tug'ilish qobiliyatini oshirish (homiladorlik qobiliyati).",
    estrozediodine2:"Yod",
    estrozediodineThyroxine:"Yod gipofiz bezi va jinsiy gormonlar bilan faol o'zaro ta'sir qiluvchi eng muhim gormon tiroksinning bir qismidir. Ayol tanasida yod etishmasligi tug'ilishning pasayishiga va hissiy inhibisyonga olib keladi. Homiladorlik davrida ushbu mikroelementning etishmasligi homilaning tug'ma nuqsonlarini keltirib chiqaradi.",
    estrozedchasteberryExtract:"Chasteberry ekstrakti",
    estrozedpregnancyRelease:"- homiladorlik gormoni progesteron ishlab chiqarishni rag'batlantiradi va shu bilan birga prolaktin gormoni chiqarilishini kamaytiradi. Yuqori darajadagi prolaktinning progesteron etishmasligi bilan birikmasi urug'lantirilgan tuxumni implantatsiya qilishni qiyinlashtiradi. Shuning uchun, Vitexni qabul qilish, homiladorlikning mumkin bo'lgan muvaffaqiyatsizligining bu sababini yo'q qiladi. Vitex ekstrakti faqat nisbatan yuqori prolaktinga ta'sir qiladi. Prolaktin darajasi normal bo'lgan ayollarda u kamaymaydi.",
    estrozedsoyIsaflavone:"Soya isaflavon",
    estrozedincreasedSweating:"Issiq chaqnashlar, terlashning ko'payishi, uyqu buzilishi, asabiy taranglik va tashvish kabi menopauza belgilarini bartaraf etish uchun ishlatiladi.",
    estrozeddrugDoesNotContain:"Preparat tarkibida: (genetik jihatdan o'zgartirilgan organizm) va gormon mavjud emas.",
    estrozedindicationsForUse:"Foydalanish uchun ko'rsatmalar:",
    estrozednormalizesMenopausePeriod:"menopauza davrini normallashtiradi;",
    estrozednormalizesThickness:"endometriumning qalinligini optimal qiymatlarga normallashtiradi;",
    estrozedfunctionalHyperprolactinemia:"funktsional giperprolaktinemiyada prolaktin darajasini pasaytiradi;",
    estrozedimprovesFemaleSystem:"ayolning reproduktiv tizimining holatini yaxshilaydi;",
    estrozeddoublesPregnancyRate:"homiladorlik darajasini ikki baravar oshiradi;",
    estrozedimprovesIVFresults:"klinik sinov ma'lumotlariga muvofiq IVF natijalarini yaxshilaydi;",
    estrozedimprovesFemaleBody:"ayol tanasining farovonligi va umumiy holatini yaxshilaydi;",
    estrozedsaturatesWithVitamins:"tanani vitaminlar va minerallar bilan to'ldiradi;",
    estrozedpreventsPrematureAging:"erta qarishni oldini oladi;",
    estrozedreplacementFemaleBody:"ayol tanasida tabiiy gormonlarni almashtirish vazifasini bajaradi, bosh og'rig'i, issiq chaqnash, uyqusizlik, asabiylashish kabi alomatlarni engillashtiradi;",
    estrozedimprovesHairAndNails:"soch va tirnoqlarning holatini yaxshilaydi;",
    estrozedbeneficialCardiovascularSystem:"yurak-qon tomir tizimiga foydali ta'sir ko'rsatadi;",
    estrozednormalizesLipidMetabolism:"lipid metabolizmini normallashtiradi, yog 'to'qimalarining shakllanishiga va kilogramm ortishiga qarshi turadi;",
    estrozedreducesBlood:"qondagi triglitseridlar va xolesterin darajasini pasaytiradi;",
    estrozedpreventsDevelopmentCancer:"osteoporoz va ko'krak saratoni rivojlanishining oldini oladi;",
    estrozedincreasesLibido:"libidoni oshiradi;",
    estrozedusedForMenstrualIrregularities:"hayz davrining buzilishi uchun ishlatiladi.",
    estrozedDosageMethod:"Dozalash usuli:",
    estrozedtakenWithMeals:"Preparat kattalar tomonidan og'iz orqali, 1 kapsuladan kuniga 2-3 marta ovqat bilan qabul qilinadi.",
    estrozeddurationTreatment:"Davolashning davomiyligi 3 oy. Mutaxassisning tavsiyasiga binoan mahsulotdan foydalanishni takrorlashingiz mumkin.",
    estrozedpregnancyMonth:"To'rtinchi oydan tug'ilgunga qadar homiladorlik, nonushtadan keyin 1 marta 1 kapsuladan oling.",
    estrozedcontraindications:"Qo'llash mumkin bo'lmagan holatlar:",
    estrozedHypersensitivityComponents:"Preparatning tarkibiy qismlariga, o'tkir va surunkali buyrak etishmovchiligi bo'lgan shaxslar va 16 yoshgacha bo'lgan bolalarga yuqori sezuvchanlik. Homiladorlikning birinchi trimestrida va emizikli ayollarda qo'llanilmaydi.",
    estrozedsideEffects:"Yon effektlar:",
    estrozedsideEffectsOccur:"Agar nojo'ya ta'sirlar yuzaga kelsa, siz preparatni qabul qilishni to'xtatishingiz va mutaxassis (shifokor) bilan maslahatlashingiz kerak.",
    estrozedstorageConditions:"Saqlash shartlari:",
    estrozedstorePoolPlace:"Salqin joyda, yorug'lik va quyosh nurlaridan himoyalangan, bolalar qo'li etmaydigan joyda, +15-25 ° C haroratda saqlang.",
    estrozedvacationConditions:"Dam olish shartlari:",
    estrozedavailableWithoutPrescription:"Retseptsiz sotiladi.",
    estrozedmanufacturer:"Ishlab chiqaruvchi:",
    estrozedlocationCompany:"'PRO-ZED' MChJ Toshkent sh., Yashnobod tumani, ko‘chasi: Parkentskaya Tel: + 998 95 301 03 00 Elektron pochta: prozed.llc@gmail.com https://www.prozed.uz",
    pageinationPrev:"Oldingi",
    pagenationNext:"Keyingisi",
    

    // ANDROZED

    androzed:"A N D R O Z E D",
    androzedsuctitle:"Kompleks vitaminov uchun mujchin",
    androzedinstrucy:"Foydalanish bo'yicha ko'rsatmalar",
    androzedtradename:"Savdo nomi: Androzed – xun takviyesi (xun takviyesi).",
    androzedcapsules:"Chiqarish shakli: har bir paket uchun 90 kapsula.",
    eandrozedcompound:"Tarkibi: L-karnitin, L-arginin, E vitamini, rux oksidi, foliy kislotasi, vitamin B6, Tongat Ali ekstrakti (Eurecoma longifolia), koenzim Q10, selen, glutation, kiyikdan olingan lutein ekstrakti, jenshen ekstrakti.",
    androzedаргинин:"L-arginin 300 mg",
    androzedкарнитин:"L-karnitin 100 mg",
    androzedvitaminE:"E vitamini 30 mg",
    androzedoxide:"Sink oksidi 10 mg",
    andtrozedolicAcid:'Foliy kislotasi 400 mkg',
    androzedmagnesiumCarbonate:"Magniy karbonat 100 mg",
    androzedvitaminB6:"B6 vitamini 1,5 mg",
    androzedextract:"Tongat Ali ekstrakti (Eurecoma longifolia) 150 mg",
    androzedCoenzyme:"Koenzim Q10 15 mg",
    androzedselenium:"Selen 50 mkg",
    androzedglutathione:"Glutation 40 mkg",
    androzedLutein: "Lutein ekstrakti (kiyikdan olingan) 50 mg",
    androzedGinseng:"Ginseng ekstrakti 50 mg",
    androzedExcipients: "Yordamchi moddalar: kraxmal, laktoza, kaltsiy stearati.",
    androzedestrozedProperties:"ANDROZED xususiyatlari va komponentning ta'sir qilish mexanizmi:",
    androzedl_аргинин:"L-arginin",
    androzedIncreases:"Konsentratsiya va harakatchanlikni, sperma morfologiyasini va eyakulyatsiya hajmini oshiradi, spermatogenezni rag'batlantiradi.",
    androzedEssentialAmino:"Inson tanasida azot oksidi miqdorini oshiradigan, sperma sekretsiyasini oshiradigan va o'sish gormoni sekretsiyasini rag'batlantiradigan muhim amino kislotalar.",
    androzedVitaminE:"E vitamini",
    androzedReducesOxidative:"Moyak to'qimalarida oksidlovchi stressni pasaytiradi, sperma harakatchanligini va konsentratsiyasini oshiradi.",
    androzedZincOxide:"Sink oksidi",
    androzedNecessarySystem:"Reproduktiv tizimning normal ishlashi va asosiy erkak gormoni testosteronning sintezi uchun zarur.",
    androzedFolicAcid:"Folik kislota (B9)",
    androzedSpermMaturation:"Spermatozoidlarning kamolotga etishi uchun zarur, nuqsonli sperma sonini kamaytirishga yordam beradi.",
    androzedVitaminB6:"Vitamin B6 (piridoksin gidroxloridi)",
    androzedImprovesMetabolism:"Miya to'qimalarida metabolizmni yaxshilaydi, chunki u aminokislotalar almashinuvi va asab tizimidagi aksariyat neyrotransmitterlarning sintezi uchun asosiy katalizator hisoblanadi. Miya faoliyatini oshiradi, xotira va kayfiyatni yaxshilaydi.",
    androzedTongatAli:"Tongat Ali (Eurecoma longifolia) ekstrakti",
    androzedmedicinalPlantFromMalaysia:"Malayziyadan olingan dorivor o'simlik, uning ildizlarida noyob biologik faol birikmalar mavjud. U xalq tabobatida ko'plab kasalliklarni davolashda (jumladan, libidoning pasayishi yoki iktidarsizlik) va sportda testosteronning chiqarilishini stimulyatori sifatida muvaffaqiyatli qo'llaniladi. Ildizlardan turli konsentratsiyali ekstraktlar tayyorlanadi. Ko'p miqdordagi faol moddalarni o'z ichiga oladi. Ulardan asosiylari evrikomanon va evripeptiddir. Birinchisi jinsiy funktsiyaga ta'sir qiladi, sperma shakllanishini kuchaytiradi, libidoni oshiradi va erektsiyani rag'batlantiradi, testosteron sekretsiyasini rag'batlantiradi. Ularning molekulalari noyob kimyoviy tuzilishga ega va tanaga kiradigan va mushaklarning o'sishiga olib keladigan ba'zi moddalarning chiqarilishiga sabab bo'ladi.",
    androzedCoenzyme:"Koenzim Q10",
    androzedBenzoquinone:"Bu benzokinon bo'lib, u quinoid guruhini (shuning uchun q belgisi) o'z ichiga oladi va 10 ta izoprenil guruhini (shuning uchun 10 raqami) o'z ichiga oladi. Bu yog'da eriydigan vitaminga o'xshash modda tanamizning aksariyat hujayralarida mavjud va asosan mitoxondriyalarda, hujayra organellalarida joylashgan. E vitamini aniq antioksidant va radioprotektiv ta'sirga ega, gem va oqsillarning biosintezida, shuningdek hujayra metabolizmining boshqa muhim jarayonlarida ishtirok etadi.",
    androzedSelenium:"Selen",
    androzedIncreasesSpermConcentration:"Spermatozoidlarning kontsentratsiyasini va harakatchanligini oshiradi va libidoga ijobiy ta'sir ko'rsatadi.",
    androzedGlutathione:"Glutation",
    androzedTripeptide:"Bu tripeptid. Bu inson organizmidagi eng kuchli antioksidantlardan biridir. Uning antioksidant ta'siri oksidlovchi stress ta'sirida sekvestrlangan tiol guruhlarini to'ldirishdir. Glutation sperma o'rta qismini barqarorlashtiradi va hujayra membranasini lipid peroksidatsiyasidan himoya qiladi. Hatto varikoselli erkaklar ham glutation qo'shimchasini qabul qilishda sperma parametrlarida sezilarli yaxshilanishlarni sezishi mumkin.",
    androzedLutein:"Lutein",
    androzedBiologicallyActiveSubstances:"Biologik faol moddalar to'plamini o'z ichiga oladi: muhim moddalar, shu jumladan aminokislotalar (triptofan, leysin, teonin, lizin, metionin, fenilalanin); nuklein kislota asoslari; o'sish omillari; sitokinlar, steroid gormonlar: progesteron, estradiol, estron, testosteron, kortizol; oqsillar, mukopolisakkaridlar (gialuron kislotasi, geparin, xondroitinsulfat kislotasi); makro va mikroelementlar (kaltsiy, natriy, kaliy, fosfor, magniy, sink, temir, marganets, mis, selen, kobalt, yod); vitaminlar va vitaminga o'xshash moddalar (A, D, C, B1, B2, B3, B6, B12, PP, xolin, inositol, lipoik kislota, karnitin); fermentlar (katalaza, lipaz, amilaza, fosfataza); muhim ko'p to'yinmagan yog'li kislotalar.",
    androzedStimulatingAndStrengtheningComponent:"Umumiy farovonlik va ongni yaxshilash, xotirani mustahkamlash, charchoqqa qarshi turish va tananing qarshiligini oshirishi mumkin bo'lgan ogohlantiruvchi va mustahkamlovchi komponent.",
    androzedIndicationsForUse:"Foydalanish uchun ko'rsatmalar:",
    androzedToNormalizeQualitative:"spermogenezning sifat va miqdoriy ko'rsatkichlarini normallashtirish;",
    androzedNormalizesSperm:"spermatozoidlarning morfo-funktsional holatini normallantiradi, ayol jinsiy a'zolarining hayotiyligini oshiradi;",
    androzedImproveTestosterone:"testosteron retseptorlarining sezgirligini oshirish, shu jumladan surunkali prostatit;",
    androzedIncreaseMaleStrength:"erkak kuchini oshirish;",
    androzedIncreaseExcitabilitySperm:"sperma sonini va qo'zg'aluvchanligini oshirish;",
    androzedMorphologicallyNormalSpermCount:"normal sperma sonini oshirish uchun morfologik;",
    androzedNutritionalSupplementForDepression:"depressiya, aqliy mehnatdan keyin charchoq va g'ayritabiiy sport mashg'ulotlaridan keyin tanani tiklash uchun ozuqaviy qo'shimcha sifatida ishlatiladi.",
    androzedDosageMethod:"Dozalash usuli:",
    androzedPrescribedAdults:"Preparat 18 yoshdan oshgan kattalarga, kuniga 2-3 marta 1 kapsuladan, ovqat paytida yoki ovqatdan keyin og'iz orqali buyuriladi.",
    androzedAfterSportsSraining:"Sport mashg'ulotlaridan so'ng va profilaktika maqsadida kuniga 1-2 marta 1 kapsuladan oling.",
    androzedDurationOfTreatment:"Davolashning davomiyligi 3 oy.",
    androzedRecommendationOfSpecialist:"Mutaxassisning tavsiyasiga binoan mahsulotdan foydalanishni takrorlashingiz mumkin.",
    androzedContraindications:"Qo'llash mumkin bo'lmagan holatlar:",
    androzedSideEffects:"Yon effektlar:",
    androzedSideEffectsOccur:"Agar nojo'ya ta'sirlar yuzaga kelsa, foydalanishni to'xtating va darhol shifokor bilan maslahatlashing.",
    StorageConditions:"Saqlash shartlari:",
    androzedStoreInCoolPlace:"Salqin joyda, yorug'lik va quyosh nurlaridan himoyalangan, bolalar qo'li etmaydigan joyda, +15-25 ° C haroratda saqlang.",
    androzedVacationConditions:"Dam olish shartlari:",
    androzedAvailableWithoutRescription:"Retseptsiz sotiladi."

    



}

export default translateUz