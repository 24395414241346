import React from 'react'
import Navbar from '../../components/navbar/navbar'
import './otziv.scss'

import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';
import ProductPage from '../../components/pagenetion/pagenetion';

const otzive = [
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQARYUtg21qngPhLGKkA3tFLJcVqSqR9QtZQg&s",
    date: '12:00',
    descrioption: "Доброго времени суток! Хочу выразить благодарность менеджеру Римме за её подход к работе. Все четко ,'без лишней воды' ,ответила на каждый вопрос. Скоординировала какой комплекс мне подойдет. Удачи и Успехов Вам в работе!"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQARYUtg21qngPhLGKkA3tFLJcVqSqR9QtZQg&s",
    date: '12:00',
    descrioption: "Доброго времени суток! Хочу выразить благодарность менеджеру Римме за её подход к работе. Все четко ,'без лишней воды' ,ответила на каждый вопрос. Скоординировала какой комплекс мне подойдет. Удачи и Успехов Вам в работе!"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQARYUtg21qngPhLGKkA3tFLJcVqSqR9QtZQg&s",
    date: '12:00',
    descrioption: "Доброго времени суток! Хочу выразить благодарность менеджеру Римме за её подход к работе. Все четко ,'без лишней воды' ,ответила на каждый вопрос. Скоординировала какой комплекс мне подойдет. Удачи и Успехов Вам в работе!"
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQARYUtg21qngPhLGKkA3tFLJcVqSqR9QtZQg&s",
    date: '12:00',
    descrioption: "Доброго времени суток! Хочу выразить благодарность менеджеру Римме за её подход к работе. Все четко ,'без лишней воды' ,ответила на каждый вопрос. Скоординировала какой комплекс мне подойдет. Удачи и Успехов Вам в работе!"
  }
]

i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})

const Otziv = () => {

  const { t } = useTranslation()

  // const changeLanguages = (value) => {
  //     i18n.changeLanguage(value)
  // }


  return (
    // <Otziv/>
    <div className="otziv">
      <div className='container'>
        <p style={{ fontWeight: '700', fontSize: "56px", lineHeight: "68.26px", color: "#17255A" }} className='pt-5 pb-3'>{t('review')}</p>
        {
          otzive.map((item, index) => {
            return (
              <div className="div">
                <img src={item.img} alt={item.name} className='otziv-img' />
                <p>{item.date}</p>
                <p>{item.descrioption}</p>
              </div>
            )
          })
        }

        <div className="">
          <p style={{ fontWeight: '700', fontSize: "56px", lineHeight: "68.26px", color: "#17255A", margin: '100px 0 50px 0' }}>{t("leaveReview")}</p>
          <form action="" className='ostalos-comment'>
            <input className='otziv_input' type="text" placeholder='Ism' />
            <input className='otziv_input my-4' type="email" placeholder='email' />
          </form>
          <textarea className='otziv_textarea' name="" id="" cols="30" rows="10" placeholder='Fikr yozing'></textarea>
        </div>
        <button className='text-light ostalos-comment-btn'>{t("sendReview")}</button>

      </div>
    </div>
  )
}

export default Otziv
