import React from 'react';
import './about.css'

const AboutPage = () => {
    return (
        <div style={{ backgroundColor: '#E4E9ED', height: '100vh' }} className='pt-4'>
            <div className="container pt-4">
                <div style={{ height: '100px', width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', borderRadius: '50%', margin: '0 auto' }}>
                    <img src='/images/logo.png' height={50} />
                </div>
                <h3 className='mt-2 text-center pb-4' style={{ fontWeight: 700, color: '#0027D0' }}>Pro Zed</h3>


                <div className='link-list'>
                    <a href='https://t.me/g41va' className='about-link' style={{ color: '#039BE5' }}>
                        <img src='/images/telegram.png' height={20} />
                        Telegram
                    </a>
                    <a href='https://t.me/g41va' className='about-link' style={{ color: '#833ab4' }}>
                        <img src='/images/instagram.png' height={20} />
                        Instagram
                    </a>
                    <a href='https://t.me/g41va' className='about-link' style={{ color: '#F61C0D' }}>
                        <img src='/images/youtube.png' height={20} />
                        YouTube
                    </a>
                    <a href='https://t.me/g41va' className='about-link' style={{ color: '#00E0A5' }}>
                        <img src='/images/web.png' height={20} />
                        Website
                    </a>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;
