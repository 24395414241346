import React from 'react'
import './ordercard.scss'


import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';

i18n.use(initReactI18next).init({
    resources: {
        uz: { translation: translateUz  },
        ru: { translation: translateRu }
    },
    lng: 'uz',
    fallbackLng: 'uz'
})


const OrderCard = () => {

    const { t } = useTranslation()

    const changeLanguages = (value) => {
        i18n.changeLanguage(value)
    }

    const changeLanguageHandler = (e) => {
        // console.log(e.target.value);
        changeLanguages(e.target.value)
    }


    return (
        <div className='order_card'>
            <h1 className='order_card_title'>{t('advice')}</h1>
                <form action="" className='form'>
                    <p className='order_card_description'>{t('writeOrder')}</p>
                    <input className='form-control order_input' type="text" placeholder='Ism' />
                    <input className='form-control order_input  my-4' type="text" placeholder='Telefon nomer/e-mail' />
                    <select className='form-control order_input  my-4' name="" id="">
                        <option defaultChecked value="">Viloyatingizni tanlang</option>
                        <option value="andijon">Andijon viloyati</option>
                        <option value="buxoro">Buxoro viloyati</option>
                        <option value="jizzax">Jizzah viloyati</option>
                        <option value="qashqadaryo">Qashqadaryo viloyati</option>
                        <option value="navoiy">Navoiy viloyati</option>
                        <option value="namangan">Namangan viloyati</option>
                        <option value="qoraqalpog'iston">Qoraqalpog'iston Res</option>
                        <option value="samarqand">Samarqand viloyati</option>
                        <option value="sirdaryo">Sirdaryo viloyati</option>
                        <option value="surxondaryo">Surxondaryo viloyati</option>
                        <option value="farg'ona">Farg'ona viloyati</option>
                        <option value="xorazm">Xorazm viloyati</option>
                        <option value="toshkent">Toshkent shaxri</option>
                    </select>
                    <button className='order_card_btn form-control'>{t("sendPetition")}</button>
                </form>
        </div>
    )
}

export default OrderCard
