
import React from 'react'
import './androzed.scss'

import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';
import { Tty } from '@mui/icons-material';

i18n.use(initReactI18next).init({
    resources: {
      uz: { translation: translateUz },
      ru: { translation: translateRu }
    },
    lng: 'uz',
    fallbackLng: 'uz'
  })



const Androzed = () => {

    const { t } = useTranslation()

    return (
        <div className="androzed">
            <div class="container">
                <h1 className='androzed_title'>{t("androzed")}
                    <p style={{fontWeight: '500px', fontSize: '16px' }}>{t("androzedinstrucy")}</p></h1>

                <h2 className='androzed_information'>{t("androzedinstrucy")}</h2>

                <h5 className='androzed_description'>{t("androzedtradename")}</h5>
                <h6>{t("androzedcapsules")}</h6>
                <h6>{t("eandrozedcompound")}</h6>
                <p>{t("androzedаргинин")}</p>
                <p>{t("androzedкарнитин")}</p>
                <p>{t("androzedvitaminE")}</p>
                <p>{t("androzedoxide")}</p>
                <p>{t("andtrozedolicAcid")}</p>
                <p>{t("androzedvitaminB6")}</p>
                <p>{t("androzedextract")}</p>
                <p>{t("androzedCoenzyme")}</p>
                <p>{t("androzedselenium")}</p>
                <p>{t("androzedglutathione")}</p>
                <p>{t("androzedLutein")}</p>
                <p>{t("androzedGinseng")}</p>

                <h5>{t("androzedExcipients")}</h5>

                <h3>{t("androzedProperties")}</h3>

                <p><h5>{t("androzedl_аргинин")}</h5>{t("androzedIncreases")}
                    <h5>{t("androzedl_аргинин")}</h5>{t("androzedEssentialAmino")}
                    <h5>{t("androzedVitaminE")}</h5>{t("androzedReducesOxidative")}
                    <h5>{t("androzedZincOxide")}</h5>{t("androzedNecessarySystem")}
                    <h5>{t("androzedFolicAcid")}</h5>{t("androzedSpermMaturation")}
                    <h5>{t("androzedVitaminB6")}</h5>{t("androzedImprovesMetabolism")}
                    <h5>{t("androzedTongatAli")}</h5>{t("androzedmedicinalPlantFromMalaysia")}
                    <h5>{t("androzedCoenzyme")}</h5>{t("androzedBenzoquinone")}</p>

                <p><h5>{t("androzedSelenium")}</h5>{t("androzedIncreasesSpermConcentration")}
                    <h5>{t("androzedGlutathione")}</h5>{t("androzedTripeptide")}
                    <h5>{t("androzedLutein")}</h5>{t("androzedBiologicallyActiveSubstances")}
                    <h5></h5>{t("androzedStimulatingAndStrengtheningComponent")}</p>


                <h5>{t("androzedIndicationsForUse")}</h5>
                <li>{t("androzedToNormalizeQualitative")}</li>
                <li>{t("androzedNormalizesSperm")}</li>
                <li>{t("androzedImproveTestosterone")}</li>
                <li>{t("androzedIncreaseMaleStrength")}</li>
                <li>{t("androzedIncreaseExcitabilitySperm")}</li>
                <li>{t("androzedMorphologicallyNormalSpermCount")}</li>
                <li>{t("androzedNutritionalSupplementForDepression")}</li>


                <h5>{t("androzedDosageMethod")}</h5>
                <p>{t("androzedPrescribedAdults")}</p>
                <p>{t("androzedAfterSportsSraining")}</p>
                <p>{t("androzedDurationOfTreatment")}</p>
                <p>{t("androzedRecommendationOfSpecialist")}</p>

                <h5>{t("androzedContraindications")}</h5>
                <p>{t("androzedHypersensitivityComponents")}</p>
                <h5>{t("androzedSideEffects")}</h5>
                <p>{t("androzedSideEffectsOccur")}</p>

                <h5>{t("StorageConditions")}</h5>
                <p>{t("androzedStoreInCoolPlace")}
                </p>
                <h5>{t("androzedVacationConditions")}</h5>
                <p>{t("androzedAvailableWithoutRescription")}</p>

                <h5>{t("estrozedmanufacturer")}</h5>

                <p>{t("estrozedlocationCompany")}</p>
            </div>
            
        </div>

    )
}

export default Androzed