import React from 'react'
import './contact.scss'
import Navbar from '../../components/navbar/navbar'
import OrderCard from '../../components/orderCard/orderCard'


import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';

i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})

const Kontakt = () => {

  const { t } = useTranslation()

  const changeLanguages = (value) => {
    i18n.changeLanguage(value)
  }

  const changeLanguageHandler = (e) => {
    // console.log(e.target.value);
    changeLanguages(e.target.value)
  }


  return (
    <div className='contact'>
      {/* <Navbar /> */}
      <div className="container">
        <p className='contact_title'>{t('call')}</p>
        <div className="contact_section">
          <div className="contact_map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11977.472458761362!2d69.2593410436782!3d41.36608631120722!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae8cc9b2decb6f%3A0x69e6501a61f0bee2!2z0K7QvdGD0YHQsNCx0LDQtC00LCAxMDAwOTMsINCi0LDRiNC60LXQvdGCLCDQotCw0YjQutC10L3RgtGB0LrQsNGPINC-0LHQu9Cw0YHRgtGMLCDQo9C30LHQtdC60LjRgdGC0LDQvQ!5e0!3m2!1sru!2s!4v1714907258804!5m2!1sru!2s" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="about_contact">
            <p className='main_offise'>{t('office')}</p>
            <p className='main_offise_location'><i style={{ color: "#FFD43B" }} class="fa-solid fa-location-dot"></i>{t('location')}</p>
            <p className='contact_phone'>{t('hpone')}</p>
            <a className='contact_phone_number' href="tel:'+998991234567">+998 88 304 00 03</a>
            <p style={{
              fontFamily: 'montserrat', fontSize: '25px', fontWeight: '500', lineHeight: '39.01px',
              color: '#17255A'
            }} className='mt-3'>{t('app')}</p>
            <div className="d-flex gap-4  my-3">
              <i class="fa-brands fa-instagram" style={{ color: '#FFD43B' }}></i>
              <i class="fa-brands fa-youtube" style={{ color: '#FFD43B' }}></i>
              <i class="fa-brands fa-telegram" style={{ color: '#FFD43B' }}></i>
            </div>
            <p style={{
              fontFamily: 'montserrat', fontSize: '25px', fontWeight: '500', lineHeight: '39.01px',
              color: '#17255A'
            }}>{t("OfficeOpeningHours")}</p>
            <p style={{ fontSize: '18px', fontWeight: '300', lineHeight: '21.94px' }}>{t("daily")}</p>
          </div>
        </div>

        <OrderCard />
      </div>
    </div>
  )
}

export default Kontakt
