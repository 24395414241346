import React, { useEffect } from 'react'
import './home.scss'
import OrderCard from '../../components/orderCard/orderCard'
import api from '../../api'
import { useTranslation } from 'react-i18next'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';
import { useNavigate } from 'react-router-dom'
import ProductPage from '../../components/pagenetion/pagenetion'

i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})

const products = [
  {
    id: 1,
    title: 'Лактозед',
    image: 'https://static4.tgstat.ru/channels/_0/2d/2d3407e5f989568e968c1efd2532205d.jpg',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  },
  {
    id: 2,
    title: 'Минивит',
    image: 'https://static4.tgstat.ru/channels/_0/2d/2d3407e5f989568e968c1efd2532205d.jpg',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  },
  {
    id: 3,
    title: 'Офтозед',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI6bn8XQOyaVZSbIpsWre33QhP12993-zfYcNMT-fY9cotlbo7I8K_zfHLv9Y_vzgVlXg&usqp=CAU',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  },
  {
    id: 3,
    title: 'Офтозед',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI6bn8XQOyaVZSbIpsWre33QhP12993-zfYcNMT-fY9cotlbo7I8K_zfHLv9Y_vzgVlXg&usqp=CAU',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  },
  {
    id: 3,
    title: 'Офтозед',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI6bn8XQOyaVZSbIpsWre33QhP12993-zfYcNMT-fY9cotlbo7I8K_zfHLv9Y_vzgVlXg&usqp=CAU',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  },
  {
    id: 3,
    title: 'Офтозед',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI6bn8XQOyaVZSbIpsWre33QhP12993-zfYcNMT-fY9cotlbo7I8K_zfHLv9Y_vzgVlXg&usqp=CAU',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  },
  {
    id: 3,
    title: 'Офтозед',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI6bn8XQOyaVZSbIpsWre33QhP12993-zfYcNMT-fY9cotlbo7I8K_zfHLv9Y_vzgVlXg&usqp=CAU',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  },
  {
    id: 3,
    title: 'Офтозед',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRI6bn8XQOyaVZSbIpsWre33QhP12993-zfYcNMT-fY9cotlbo7I8K_zfHLv9Y_vzgVlXg&usqp=CAU',
    benefits: [
      'Улучшение качества сна',
      'Легкость пробуждения',
      'Подъем работоспособности',
      'Улучшение памяти',
      'Снижение утомляемости'
    ]
  }
];

// const experts = [
//   {
//     name: 'Ахроржон Аскарович',
//     description: 'Эксперт в сфере медицины и филологии и еще что нибудь там, стаж работы 10 лет',
//     image: 'https://picsum.photos/300/300'
//   },
//   {
//     name: 'Ахроржон Аскарович',
//     description: 'Эксперт в сфере медицины и филологии и еще что нибудь там, стаж работы 10 лет',
//     image: 'https://picsum.photos/300/300'
//   },
//   {
//     name: 'Ахроржон Аскарович',
//     description: 'Эксперт в сфере медицины и филологии и еще что нибудь там, стаж работы 10 лет',
//     image: 'https://picsum.photos/300/300'
//   },
//   {
//     name: 'Ахроржон Аскарович',
//     description: 'Эксперт в сфере медицины и филологии и еще что нибудь там, стаж работы 10 лет',
//     image: 'https://picsum.photos/300/300'
//   }
// ];

const Home = () => {



  async function getUser() {
    const endPoint = `/product`
    try {
      const response = await api.get(endPoint)
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUser()
  }, [])

  const navigateCompany = useNavigate();
  const navigateInformation = useNavigate();
  const navigateEstrozed = useNavigate();
  const navigateAndrozed = useNavigate();
  const { t } = useTranslation()

  const handleNavigationCompany = () => {
    navigateCompany('/okompani');
  };

  const handleNavigationInform = () => {
    navigateInformation('/kontakt');
  };

  const handleNavigatiEstrozed = () => {
    navigateEstrozed('/estrozed');
  };

  const handleNavigatiAndrozed = () => {
    navigateAndrozed('/androzed');
  };

  return (
    <>
      <div className='header'>
        <div className="container">
          <h1 className='header_title text-light'>{t("headerTitle")}</h1>
          <h2 className='header_replase_title'>OOO "PRO-ZED"</h2>
          <p className='header_description text-light mb-5'>{t("headerDescription")}</p>
          <a style={{ textDecoration: 'none' }} href="#checimal"><button className='header_btn'>{t("headerBtn")}</button></a>
        </div>
      </div>
      {/* hero */}
      <div className="hero">
        <div className="container pt-4">
          <div className="hero_section">
            <div className="hero_main_foto">
              <div className="hero_img">
                <img style={{ width: "100%", height: 'auto' }} className="hero_main_img" src="../images/pages/baby.jpg" alt="women" />
              </div>
            </div>
            <div className="hero_body">
              <div className="hero_body_custom_container mb-3">
                <img className='hero_body_custom_container_img' src="../images/pages/estrozed 1.png" alt="" />
                <img className='hero_body_custom_container_img' src="../images/pages/androzed.png" alt="" />
              </div>
              <div className="hero_body_baby">
                <img className='hero_body_baby_img' src="../images/pages/family.avif" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <div className="home_estrozed">
        <div className='container'>
          <div className="main_chamical">
            <div className="main_chamical_estrozed">
              <img className='main_chamical_estrozed_img' src="../images/pages/estrozed 1.png" alt="Estrozed" />
              <p className='main_chamical_title'>{t("estrozed")}</p>
              <ul>
                <li className='main_chamical_desctiption'>{t("sleepQuality")}</li>
                <li className='main_chamical_desctiption'>{t("excitabilityOfSperm")}</li>
                <li className='main_chamical_desctiption'>{t("excitabilityOfSperm")}</li>
                <li className='main_chamical_desctiption'>{t("spermCount")}</li>
                <li className='main_chamical_desctiption'>{t("supplementDepression")}</li>
              </ul>
              <button onClick={handleNavigatiEstrozed} className='main_chamical_btn'>{t("informProduct")}</button>
            </div>
            <div className="main_chamical_estrozed">
              <img className='main_chamical_estrozed_img' src="../images/pages/androzed.png" alt="Androzed" />
              <p className='main_chamical_title'>Androzed</p>
              <ul>
                <li className='main_chamical_desctiption'>{t("normalizesMenopause")}</li>
                <li className='main_chamical_desctiption'>{t("normalizesEndometrial")}</li>
                <li className='main_chamical_desctiption'>{t("bodyVitamins")}</li>
                <li className='main_chamical_desctiption'>{t("bodyVitamins")}</li>
                <li className='main_chamical_desctiption'>{t("improvesCondition")}</li>
                <li className='main_chamical_desctiption'>{t("improvesFemaleBody")}</li>
              </ul>
              <button onClick={handleNavigatiAndrozed} className='main_chamical_btn'>{t("informProduct")}</button>

            </div>
          </div>
        </div>
      </div>
      <ProductPage data={products} type="products" />
      {/* products */}
      <div className="container">
        <h1 className='what_is_bady'>{t("whatIsBad")}</h1>
        <div className="what_is_bady_inform">
          <p className="what_is_bady_inform_description">{t("whatIsBadDescription")}</p>
        </div>
      </div>
      {/* recomendatcy */}
      {/* <ExpertRecommendations experts={experts} /> */}
      {/* about prozed */}
      <div className="prozed">
        <div className="container">
          <div className="prozed_section">
            <div className="prozed_info">
              <p className='prozed_info_title'>PRO-ZED</p>
              <p className='prozed_info_description'>{t("aboutProzed")}</p>
              <p className='prozed_info_description'>{t("originCompany")}</p>
              <p className='prozed_info_description'>{t("drivenDeep")}</p>
              <p className='prozed_info_description'>{t("PROZEDLLC")}</p>
              <button onClick={handleNavigationCompany} className='prozed_info_btn'>{t("btnocompany")}</button>
            </div>
            <div className="prozed_img">
              <img style={{ width: "100%" }} src="./images/pages/Vector (1).png" alt="" />
            </div>
          </div>

        </div>
        {/* prozed deliver */}
      </div>
      <div className="container">
        <div className="prozed_deliver">
          <p className='prozed_deliver_title'>{t("freeDeliver")}</p>
          <button onClick={handleNavigationInform} className='prozed_deliver_btn'>{t("knowMoreBtn")}</button>
        </div>
      </div>
      <OrderCard />
    </>
  )
}

export default Home
