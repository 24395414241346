const translateRu = {
    about: "Главная",
    aboutUs: "O компании",
    review: 'Отзывы',
    contact: 'Контакты',
    call: 'Контакты',
    email: 'Электронная почта:',
    address: 'Адрес',
    location: "г. Ташкент, Мирзо-Улугбекский р-н, МСГ Шахриобод, ул: Олий Химмат",
    sleepQuality:"Для нормализации качественных и количественных показателей спермогенеза",
    increaseMaleStrength:"Для увеличения мужской силы",
    excitabilityOfSperm:"Для увеличения количества и возбудимости сперматозоидов",
    spermCount:"Морфологически для увеличения нормального количества сперматозоидов",
    supplementDepression:"Применяется в качестве пищевой добавки при депрессии, утомительных состояниях после умственной трудовой деятельности и для восстановления организма после отклоняющихся спортивных нагрузок",
    normalizesMenopause:"Нормализует период менопаузы",
    normalizesEndometrial:"Нормализует толщину эндометрия до оптимальных значений",
    bodyVitamins:"Насыщает организм витаминами и минералами",
    improvesCondition:"Улучшает состояние женской репродуктивной системы",
    improvesFemaleBody:"Улучшает самочувствие и общее состояние женского организма",
    doublesPregnancy:"Удваивает частоту наступления беременности",
    supplementDepression:"Применяется в качестве пищевой добавки при депрессии, утомительных состояниях после умственной трудовой деятельности и для восстановления организма после отклоняющихся спортивных нагрузок",

    app: 'Соц. сети',
    companyName: 'Название компании',
    products: 'Наша продукция:',
    advice: "Заполните анкету ниже и наши менеджеры с вами свяжутся",
    writeOrder: "Заполните анкету ниже и наши менеджеры с вами свяжутся",
    sendPetition: "Оставить заявку",
    office: "Главный офис",
    hpone: "Tелефон:",
    OfficeOpeningHours: "Время работы офиса:",
    daily: "Каждый день с 8.00 до 17.00",
    review: "Отзывы",
    leaveReview: 'Оставьте отзыв',
    sendReview: "Отправить отзыв",
    certificateNumber:"Номер удостоверения:",
    headerTitle: 'OOO "PRO-ZED',
    headerDescription: 'Станте счастливым родителем с нашими продуктами. Вы достойны самого лучшего!!',
    headerBtn: "Наша продукция",
    buy: "Приобрести",
    informProduct: "Подробнее о продукте",
    whatIsBad: "Что такое БАДы?",
    whatIsBadDescription: "Биологически активные пищевые добавки (БАД)-это биологически активные вещества и их состав, предназначенные для непосредственного приема с пищей или введения в пищу. Биологически активными добавками являются природные комплексы, содержащие минералы, витамины, пищевые волокна, экстракты растений, ненасыщенные жирные кислоты, аминокислоты и др. Они устраняют недостатки в питании, улучшают усвоение полезных веществ, а также повышают внутреннюю силу организма, снижая риск многих заболеваний. Комплекс БАД получают в основном из листьев, цветков, корней и плодов растений, при их приготовлении иногда используют также бактерии, микробы, органы некоторых животных и минеральное сырье.",
    aboutProzed: "Раскройте свой потенциал здоровья с помощью передовых биологически активных добавок!",
    originCompany:"Зарождение компании Добро пожаловать в OOO «PRO-ZED», где страсть к оздоровлению сочетается с научным опытом! Мы основали нашу компанию с единственной миссией: помогать людям на их пути к оптимальному здоровью и жизненной силе.",
    drivenDeep:"Руководствуясь глубоким пониманием жизненно важной роли, которую питание играет в нашем благополучии, мы решили создать линейку пищевых добавок премиум-класса, которые используют силу природы и научные инновации. Наша приверженность качеству и эффективности стала краеугольным камнем нашего бренда, гарантируя, что каждый предлагаемый нами продукт изготовлен с особой тщательностью и подкреплен обширными исследованиями.",
    PROZEDLLC:"В OOO «PRO-ZED» мы верим, что истинное хорошее самочувствие достигается благодаря целостному подходу. Мы стремимся предоставить вам широкий выбор добавок, которые направлены на достижение широкого спектра целей в области здравоохранения, от повышения энергии и поддержания иммунитета до улучшения когнитивных функций и поддержания сбалансированного настроения.",


    btnocompany: "О компании",
    freeDeliver: "Бесплатная доставка по всему Узбекистану",
    knowMoreBtn: "Узнать больше",
    биологическиAктивных: "Раскройте свой потенциал здоровья с помощью передовых биологически активных добавок!",
    aboutCompany: "Зарождение компании",
    aboutCompanyDescription: "Добро пожаловать в <strong>[Название компании], где страсть к оздоровлению сочетается с научным опытом! Мы основали нашу компанию с единственной миссией: помогать людям на их пути к оптимальному здоровью и жизненной силе. Руководствуясь глубоким пониманием жизненно важной роли, которую питание играет в нашем благополучии, мы решили создать линейку пищевых добавок премиум-класса, которые используют силу природы и научные инновации. Наша приверженность качеству и эффективности стала краеугольным камнем нашего бренда, гарантируя, что каждый предлагаемый нами продукт изготовлен с особой тщательностью и подкреплен обширными исследованиями. В [Название компании] мы верим, что истинное хорошее самочувствие достигается благодаря целостному подходу. Мы стремимся предоставить вам широкий выбор добавок, которые направлены на достижение широкого спектра целей в области здравоохранения, от повышения энергии и поддержания иммунитета до улучшения когнитивных функций и поддержания сбалансированного настроения.",
    ourAchievement: "Наши достижения",
    companyAge: "2 лет",
    sinceOpened: "с момента открытия компании",
    achievedResalts: "клиенты добившиеся результатов",
    boughtProducts: "Купили и употребили нашу продукцию",
    certificates:'Сертификаты',
    aboutFounder:"Об основателе",
    AksenovaValentina:"Аксёнова Валентина Ивановна – главный эпидемиолог Московской медицинской Палаты Правительства Москвы, Магистр Натуральной медицины, доктор макробиологических наук, профессор, доктор психологических наук. Основатель и научный руководитель компании «Оптисалт», эксперт в области паразитологии, гельминтологии, микроэлементологии, разработки комплексных биоактивных лекарственных компонентов (нутрицевтиков) с практическим опытом более 20 лет.",
    EuropeanAcademy:" Член Европейской академии естественных наук, Член Российского научного общества Натуральной медицины, Член «Лиги здоровья нации», Член Некоммерческой организации Натуротерапии. Член консультативного совета Международной Балканской Академии, комплементарной и альтернативной медицины.",
    readMore:"Читать подробнее",
    ourPartners:"Наши партнеры",
    pageinationPrev:"Предыдущая",
    pagenationNext:"Следующая",



    // ESTROZED

    estrozed:"Э С Т Р О З Е Д",
    estrozedabout: '(Подготовка к беременности + комплекс витаминов)',
    estrozedinstrucy: 'Инструкция по применению',
    estrozedtradename: 'Торговое название: Эстрозед – БАД (биологически активная добавка).',
    estrozedcapsules: "Форма выпуска: 90 капсулы в упаковке.",
    estrozedcompound: "Состав: L-аргинин, L-карнитин, Витамин E, Оксид цинка, Фолиевая кислота, Магния карбонат, Витамин B6, Витамин B8, Витамин C, Коэнзим Q10, Селен, Йод, Экстракт витекса священного (содержит иридиоды), Соевый изафлавон.",
    estrozedаргинин: "L- аргинин						100 мг	",
    estrozedкарнитин: "L-карнитин	200 мг		",
    estrozedВитаминE: "Витамин Е 18 мг",
    estrozedoxide: "Оксид цинка 10 мг",
    estrozedfolicAcid: "фолиевая кислота 40 мкг",
    estrozedmagnesiumCarbonate: "Магния карбонат	100 мг	",
    estrozedvitaminB6: "Витамин B6						1,8 мг",
    estrozedvitaminB8: "Витамин B8						65 мг",
    estrozedvitaminC: "Витамин C						30 мг",
    estrozedCoenzyme: "Коэнзим Q10		15 мг",
    estrozedselenium: "Селен	40 мкг",
    estrozediodine: "Йод		150 мкг	",
    estrozedchasteberry: "Экстракт витекса священного (содержит иридиоды)		40 мг",
    estrozedisaflavone: "Соевый изафлавон		100 мг",
    estrozedexcipients: "Вспомогательные вещества: крахмал, лактоза, стеарат кальция.",
    estrozedProperties: "ЭСТРОЗЕД-свойства и механизм действия компонента:",
    estrozedl_аргинин: "L-аргинин",
    estrozedaminoAcid: "Аминокислота, из которой образуется оксид азота, способствующий расширению сосудов и, как следствие, влияющий на улучшение кровообращения и насыщение тканей кислородом, в т.ч. в органах малого таза. Это улучшает состояние органов репродуктивной системы и повышает вероятность зачатия.",
    estrozedl_carnitine: "L-карнитин",
    estrozedforWeightLoss: "Для похудения, повышения выносливости при перегрузках, в том числе спортивных, в период менопаузы и диетах. Он помогает сжигать лишний жир, наращивать мышцы, поддерживать тело в тонусе и улучшать работу мозга и сердца.",
    estrozedvitaminE2: "Витамин Е",
    estrozedimprovesOxygen: "Улучшает насыщение тканей кислородом, необходим для метаболизма белков, жиров и углеводов. Не менее важна и антиоксидантная функция витамина Е, благодаря которой он защищает клетки от вредного воздействия свободных радикалов. При гиповитаминозе Е женщина постоянно ощущает нарастающую мышечную слабость, боли, повышается риск непроизвольного аборта. Нехватка витамина Е в организме матери может сказаться на недоношенных детях - у них возможны нарушения зрения и гемолитическая анемия.",
    estrozedzinc: "Цинк",
    estrozedpregnancy: "Один из самых важных элементов в период беременности. Необходим для работы генетического аппарата, требуется для образования и развития ядра клетки, а также для роста и деления клеток. Цинк участвует в регуляции синтеза нуклеиновых кислот, белков, жиров и углеводов. Прием цинка помогает предупредить преждевременную отслойку плаценты, осложненные роды, врожденные пороки развития ребенка и другие проблемы.",
    estrozedfolicAcid: "Фолиевая кислота",
    estrozednecessaryGrowth: "Необходима для деления и роста клеток, поэтому в период быстрого развития организма (на стадии раннего внутриутробного развития) требуется в повышенных количествах. Также фолиевая кислота играет важную роль в формировании плаценты и новых кровеносных сосудов в матке. Прием фолиевой кислоты в период подготовки к зачатию и во время беременности снижает риск преждевременного прерывания беременности, кровотечений, развития дефектов нервной трубки у новорожденных.",
    estrozedmagnesium: "Магний",
    estrozedbiochemicalProcesses: "Регулирует более 300 биохимических процессов в организме, включая синтез белка и активацию аминокислот, которые особенно интенсивны во время беременности. Также магний обеспечивает передачу электрических сигналов между клетками. Дефицит магния часто встречается у женщин и особенно опасен во время беременности, поскольку может привести к замедлению роста плода, выкидышу, эклампсии гестационное заболевание, связанное с судорогами и высоким кровяным давлением.",
    estrozedvitaminB6: "Витамин В6",
    estrozedaminoAcids: "Необходим для синтеза аминокислот, из которых образуются белки - основной 'строительный материал' для клеток нового организма. Также обеспечивает нормальное развитие мозга и нервной системы ребенка. Кроме того, витамин Вб снижает раздражительность и агрессивность, уменьшает проявление токсикозов в первой половине беременности.",
    estrozedвитаминВ8: "Витамин В8",
    estrozedforWomen: "Для женщин это одно из важнейших витаминоподобных веществ, три четверти потребности организм вырабатывает сам, а остальное получает с пищей. Исследования показывают, что дополнительный прием инозита с пищей способствует нормализации гормонального баланса у представительниц прекрасного пола.",
    estrozedvitaminC:"Витамин С",
    estrozedsynthesisOfCollagen:"Поддерживает синтез коллагена (основного соединительного элемента стенок сосудов и костной ткани), принимает участие в метаболизме холестерина, процессах углеводного обмена и многих важных биохимических реакциях. Также витамин С укрепляет капилляры и обладает противовоспалительными свойствами. Дефицит витамина С может привести к преждевременному разрыву плодных оболочек.",
    estrozedcoenzyme:"Коэнзим Q10",
    estrozedhealthWholeBody:"Относится к группе антиоксидантов, которые напрямую связаны с молодостью и здоровьем всего организма. Антиоксиданты помогают продлить жизненный цикл клеток, защищая их стенки от разрушения свободными радикалами. Помимо защиты, кофермент выполняет мощную энергетическую функцию. Он участвует в сложных биохимических процессах высвобождения энергии из клеток, влияет на самочувствие и работоспособность. Другими словами, коэнзим Q10-это естественная 'батарея' для нашего организма. Его недостаток приводит к старению кожи, снижению мозговой активности и ослаблению иммунитета.",
    estrozedselenium:"Селен",
    estrozedincreasingFertility:"Входит в состав большого количества ферментов, в т.ч. повышающих фертильность (способность к зачатию).",
    estrozediodine2:"Йод",
    estrozediodineThyroxine:"Йод входит в состав важнейшего гормона тироксина, который активно взаимодействует с гипофизом и половыми гормонами. Недостаток йода в женском организме приводит к снижению фертильности и эмоциональному торможению. Недостаток этого микроэлемента при беременности провоцирует врожденные дефекты плода.",
    estrozedchasteberryExtract:"Экстракт витекса священного",
    estrozedpregnancyRelease:"- стимулирует выработку гормона беременности прогестерона и одновременно снижает выделение гормона пролактина. Сочетание высокого уровня пролактина с недостатком прогестерона затрудняет имплантацию оплодотворенной яйцеклетки. Поэтому прием витекса устраняет эту причину возможного ненаступления беременности. Экстракт витекса оказывает влияние только на относительно повышенный пролактин. У женщин с нормальным уровнем пролактина не происходит его снижения.",
    estrozedsoyIsaflavone:"Соевый изафлавон",
    estrozedincreasedSweating:"Используется для облегчения симптомов менопаузы, таких как приливы, повышенное потоотделение, нарушения сна, нервное напряжение и чувство тревоги.",
    estrozeddrugDoesNotContain:"Препарат не содержит: (генетически модифицированный организм) и гормона.",
    estrozedindicationsForUse:"Показания по применению:",
    estrozednormalizesMenopausePeriod:"нормализует период менопаузы;",
    estrozednormalizesThickness:"нормализует толщину эндометрия до оптимальных значений;",
    estrozedfunctionalHyperprolactinemia:"снижает уровень пролактина при функциональной гиперпролактинемии.;",
    estrozedimprovesFemaleSystem:"улучшает состояние женской репродуктивной системы;",
    estrozeddoublesPregnancyRate:"удваивает частоту наступления беременности;",
    estrozedimprovesIVFresults:"улучшает результаты ЭКО в соответствии с данными клинических испытаний;",
    estrozedimprovesFemaleBody:"улучшает самочувствие и общее состояние женского организма;",
    estrozedsaturatesWithVitamins:"насыщает организм витаминами и минералами;",
    estrozedpreventsPrematureAging:"предотвращает преждевременное старение;",
    estrozedreplacementFemaleBody:"действует как естественная замена гормонов в женском организме, облегчая такие симптомы, как головные боли, приливы, бессонница, раздражительность;",
    estrozedimprovesHairAndNails:"улучшает состояние волос и ногтей;",
    estrozedbeneficialCardiovascularSystem:"благотворно влияет на сердечно-сосудистую систему;",
    estrozednormalizesLipidMetabolism:"нормализует липидный обмен, противодействуя образованию жировой ткани и увеличению веса;",
    estrozedreducesBlood:"снижает уровень триглицеридов и холестерина в крови.;",
    estrozedpreventsDevelopmentCancer:"предотвращает развитие остеопороза и рака груди;",
    estrozedincreasesLibido:"повышает либидо;",
    estrozedusedForMenstrualIrregularities:"используется при нарушениях менструального цикла.",
    estrozedDosageMethod:"Способ применения дозы:",
    estrozedtakenWithMeals:"Препарат принимают внутрь взрослым по 1 капсуле 2-3 раза в день во время еды.",
    estrozeddurationTreatment:"Длительность приема 3 месяца. Прием средства по рекомендации специалиста можно повторить.",
    estrozedpregnancyMonth:"Беременность с четвертого месяца до родов принимают по 1 капсуле 1 раз после завтрака.",
    estrozedcontraindications:"Противопоказания:",
    estrozedHypersensitivityComponents:"Гиперчувствительность к компонентам препарата, лицам с острой и хронической почечной недостаточностью и детям до 16 лет. Не применяется в первом триместре беременности и у кормящих женщин.",
    estrozedsideEffects:"Побочные эффекты: ",
    estrozedsideEffectsOccur:"При появлении побочных эффектов прием препарата следует прекратить и обратиться к специалисту (врачу).",
    estrozedstorageConditions:"Условия хранения: ",
    estrozedstorePoolPlace:"Хранить в прохладном, защищенном от света и солнечных лучей месте, недоступном для детей, при температуре +15-25 °С.",
    estrozedvacationConditions:"Условия отпуска: ",
    estrozedavailableWithoutPrescription:"Отпускают без рецепта.",
    estrozedmanufacturer:"Производитель:",
    estrozedlocationCompany:"ООО 'PRO-ZED' г. Ташкент, Яшнабадский рьн, ул: Паркентская Тел: + 998 95 301 03 00 E-mail: prozed.llc@gmail.com https://www.prozed.uz",
    

    // ANDROZED

    androzed:" А Н Д Р О З Е Д",
    androzedsuctitle:"(Комплекс витаминов для мужчин)",
    androzedinstrucy:"Инструкция по применению",
    androzedtradename:"Торговое название: Андрозед – БАД (биологически активная добавка).",
    androzedcapsules:"Форма выпуска: 90 капсулы в упаковке.",
    eandrozedcompound:"Состав: L-карнитин, L-аргинин,Витамин E, Оксид цинка, Фолиевая кислота, Витамин B6, Экстракт Тонгат Али (эврикома длиннолистная), Коэнзим Q10, Селен, Глютатион, Лютеин экстракта полученного от марала, Экстракт женьщеня.",
    androzedаргинин: "L- аргинин						100 мг	",
    androzedкарнитин: "L-карнитин	200 мг		",
    androzedВитаминE: "Витамин Е 18 мг",
    androzedoxide: "Оксид цинка 10 мг",
    androzedfolicAcid: "фолиевая кислота 400 мкг",
    androzedvitaminB6: "Витамин B6						1,5 мг",
    androzedextract: "Экстракт Тонгат Али (эврикома длиннолистная)				150 мг	",
    androzedCoenzyme: "Коэнзим Q10		15 мг",
    androzedselenium: "Селен	50 мкг",
    androzedglutathione: "Глютатион 40 мкг",
    androzedLutein: "Лютеин экстракта (полученного от марала)		50 мг",
    androzedGinseng: "Экстракт женьщеня		50 мг",
    androzedExcipients: "Вспомогательные вещества: крахмал, лактоза, стеарат кальция.",
    androzedProperties: "АНДРОЗЕД-свойства и механизм действия компонента:",
    androzedl_аргинин: "L-аргинин",
    androzedIncreases:"Повышает концентрацию и подвижность, морфологию сперматозоидов и объем эякуляции, стимулирует сперматогенез.",
    androzedEssentialAmino:"Незаменимая аминокислота, которая увеличивает количество оксида азота в организме человека, увеличивает секрецию сперматозоидов и стимулирует секрецию гормона роста.",
    androzedVitaminE:"Витамин Е",
    androzedReducesOxidative:"Снижает окислительный стресс в ткани яичка, повышает подвижность и концентрацию сперматозоидов.",
    androzedZincOxide:"Оксид цинка",
    androzedNecessarySystem:"Необходим для нормальной работы репродуктивной системы и синтеза основного мужского гормона-тестостеона.",
    androzedFolicAcid:"Фолиевая кислота (B9)",
    androzedSpermMaturation:"Необходима для созревания сперматозоидов, помогает уменьшить количество дефектных сперматозоидов.",
    androzedVitaminB6:"Витамин В6 (пиридоксина гидрохлорид)",
    androzedImprovesMetabolism:"Улучшает обмен веществ в тканях головного мозга, так как является основным катализатором аминокислотного обмена, синтеза большинства нейротрансмиттеров нервной системы. Повышает работоспособность мозга, способствует улучшению памяти и настроения.",
    androzedTongatAli:"Экстракт тонгат Али (эврикома длиннолистная)",
    androzedmedicinalPlantFromMalaysia:"Лекарственное растение из Малайзии, корни которого содержат уникальные биологически активные соединения. Успешно применяется в народной медицине при лечении многих заболеваний (в том числе снижения либидо или импотенции) и в качестве стимулятора высвобождения тестостерона в спорте. Из корней готовят экстракт разной концентрации. Содержит большое количество активных веществ. Основными являются эврикоманон и еврипептид. Первые влияют на сексуальную функцию, усиливают образование сперматозоидов, повышают либидо и стимулируют эрекцию.Эврипептиды оказывают анаболическое действие, стимулируя секрецию тестостерона. Их молекулы имеют уникальную химическую структуру и вызывают высвобождение определенных веществ, которые попадают в организм и вызывают рост мышц.",
    androzedCoenzyme:"Коэнзим Q10",
    androzedBenzoquinone:"Это бензохинон, который содержит хиноидную группу (отсюда q-символ) и содержит 10 изопренильных групп (отсюда число 10). Это жирорастворимое витаминоподобное вещество содержится в большинстве клеток нашего тела и в основном содержится в митохондриях - клеточных органеллах. Витамин Е обладает выраженным антиоксидантным и радиозащитным действием, участвует в биосинтезе гема и белков, а также в других важных процессах клеточного метаболизма.",
    androzedSelenium:"Селен",
    androzedIncreasesSpermConcentration:"Повышает концентрацию и подвижность сперматозоидов, положительно влияет на либидо.",
    androzedGlutathione:"Глутатион",
    androzedTripeptide:"Это трипептид. Это один из самых мощных антиоксидантов в организме человека. Его антиоксидантное действие заключается в восполнении тиольных групп, которые подвергаются секвестрации под действием окислительного стресса. Глутатион стабилизирует среднюю часть сперматозоида и защищает клеточную мембрану от перекисного окисления липидов. Даже у мужчин с варикоцеле параметры спермы могут значительно улучшиться при приеме добавки глутатиона.",
    androzedLutein:"Лютеин",
    androzedBiologicallyActiveSubstances:"Содержит набор биологически активных веществ: незаменимые вещества, в том числе аминокислоты (триптофан, лейцин, теонин, лизин, метионин, фенилаланин); основания нуклеиновых кислот; факторы роста; цитокины, стероидные гормоны: прогестерон, эстрадиол, эстетрон, тестостерон, кортизол; белки, мукополисахариды (гиалуроновая кислота, гепарин хондроитинсеровой кислоты); макро-и микроэлементы (кальций, натрий, калий, фосфор, магний, цинк, железо, марганец, медь, селен, кобальт, йод); витамины и витаминоподобные вещества (А, D, С, В1, В2, В3, В6, В12, РР, холин, инозитол, липоевая кислота, карнитин); ферменты (каталаза, липаза, амилаза, фосфатаза); незаменимые полиненасыщенные жирные кислоты.",
    androzedStimulatingAndStrengtheningComponent:"Стимулирующий и укрепляющий компонент, способный улучшить общее самочувствие и сознание, укрепить память, противостоять усталости, повысить сопротивляемость организма.",
    androzedIndicationsForUse:"Показания по применению:",
    androzedToNormalizeQualitative:"для нормализации качественных и количественных показателей спермогенеза;",
    androzedNormalizesSperm:"нормализует Морфо-функциональное состояние сперматозоидов, увеличивает жизнеспособность женских репродуктивных органов;",
    androzedImproveTestosterone:"для зашивания чувствительности рецепторов к тестостерону, в том числе при хроническом простатите;",
    androzedIncreaseMaleStrength:"для увеличения мужской силы;",
    androzedIncreaseExcitabilitySperm:"для увеличения количества и возбудимости сперматозоидов;",
    androzedMorphologicallyNormalSpermCount:"морфологически для увеличения нормального количества сперматозоидов;",
    androzedNutritionalSupplementForDepression:"применяется в качестве пищевой добавки при депрессии, утомительных состояниях после умственной трудовой деятельности и для восстановления организма после отклоняющихся спортивных нагрузок.",
    androzedDosageMethod:"Способ применения дозы:",
    androzedPrescribedAdults:"Препарат назначают взрослым от 18 лет по 1 капсуле 2-3 раза в день, внутрь во время еды или после еды.",
    androzedAfterSportsSraining:"После спортивной тренировки и в профилактических целях принимают по 1 капсуле 1-2 раза в день.",
    androzedDurationOfTreatment:"Продолжительность приема 3 месяца. ",
    androzedRecommendationOfSpecialist:"Прием средства по рекомендации специалиста можно повторить.",
    androzedContraindications:"Противопоказания:",
    androzedHypersensitivityComponents:"Гиперчувствительность к компонентам препарата, лицам с острой и хронической почечной недостаточностью и детям до 18 лет.",
    androzedSideEffects:"Побочные эффекты:",
    androzedSideEffectsOccur:"При возникновении побочных эффектов прекратите использование и немедленно обратитесь к врачу.",
    StorageConditions:"Условия хранения: ",
    androzedStoreInCoolPlace:"Хранить в прохладном, защищенном от света и солнечных лучей месте, недоступном для детей, при температуре +15-25 °С.",
    androzedVacationConditions:"Условия отпуска: ",
    androzedAvailableWithoutRescription:"Отпускают без рецепта."
}

export default translateRu