import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../components/navbar/navbar'
import Footer from '../components/footer/footer'
import i18n from 'i18next'

const Authlayout = () => {

  const changeLanguages = (value) =>{
    i18n.changeLanguage(value)
  }
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer changeLanguages={changeLanguages}/>
    </div>
  )
}

export default Authlayout
